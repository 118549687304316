// @flow

import React from "react";
import styled from "styled-components";
import { transparentize } from "polished";
import Img from "gatsby-image";
import type { GatsbyImageSizes } from "gatsby-image";
import Title from "./Title";
import { primary, foreground } from "../utils/colors";
import { maxWidth, media, sideSpacing } from "../utils/sizes";
import { rhythm } from "../utils/typography";

const Wrapper = styled.div`
  position: relative;
`;

const TitleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: ${transparentize(0.2, foreground)};
  z-index: 1;
`;
const InsetTitle = Title.extend`
  color: ${primary};
  font-size: ${rhythm(3 / 2)};
  max-width: ${maxWidth};
  padding: ${rhythm(1)} ${sideSpacing};
  margin: 0 auto;
  ${media.phone`
    font-size: ${rhythm(1)};
    padding: ${rhythm(1 / 2)} ${sideSpacing};
  `};
`;

type Props = { title: string, sizes: GatsbyImageSizes, maxHeight?: string };
const HeroImage = ({ title, sizes, maxHeight }: Props) => (
  <Wrapper>
    <TitleWrapper>
      <InsetTitle>{title}</InsetTitle>
    </TitleWrapper>
    <Img sizes={sizes} style={{ maxHeight }} />
  </Wrapper>
);
HeroImage.defaultProps = { maxHeight: "" };

export default HeroImage;
