// @flow
/* eslint-disable no-restricted-globals, no-undef, jsx-a11y/no-static-element-interactions, jsx-a11y/click-events-have-key-events */

import React, { PureComponent } from "react";
import { Link, push } from "gatsby";
import styled from "styled-components";
import { transparentize } from "polished";
import { maxWidth, media, sideSpacing } from "../utils/sizes";
import { rhythm, scale } from "../utils/typography";
import {
  primary,
  background,
  foreground,
  accentDark,
  accentDarker,
  accentLight,
  accentLighter,
  shadow,
} from "../utils/colors";
import { fadeIn } from "../utils/animations";
import searchIcon from "./search.svg";

const navHeight = rhythm(2);

const NavContainer = styled.header`
  background-color: ${primary};
  height: ${navHeight};
  transition: background-color 100ms;
  z-index: 10; // HeroImage has a z-index of 1
`;

const BackdropElement = styled.div`
  display: none;
  ${media.tablet`
    animation: ${fadeIn} 300ms ease-in;
    background-color: ${foreground};
    display: block;
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    z-index: 10;  // HeroImage has a z-index of 1
  `};
`;
const Backdrop = ({
  toggle,
}: {
  toggle: (SyntheticEvent<HTMLElement>) => void,
}) => <BackdropElement onClick={toggle} />;

const Header = styled.div`
  margin: 0 auto;
  max-width: ${maxWidth};
  z-index: 10; // HeroImage has a z-index of 1

  ${media.tablet`
    position: fixed;
    top: 0;
    bottom: 0;
    transform: translate3d(
      ${props => (props.open ? 0 : rhythm(-10))}, 0, 0);
    width: ${rhythm(10)};
    background-color: ${background};
    box-shadow: ${props =>
      props.open ? `${rhythm(1 / 4)} 0 ${rhythm(1 / 4)} 0 ${shadow}}` : "none"};
    overflow: auto;
    padding: 0;
    transition: transform 300ms ease-in;
  `};
`;
const SidebarToggle = styled.button`
  display: none;
  ${media.tablet`
    ${scale(1.3)};
    background: ${transparentize(0.3, primary)};
    border: none;
    cursor: pointer;
    display: block;
    font-weight: lighter;
    height: ${navHeight};
    transform: translate3d(
      ${props => (props.open ? rhythm(8) : sideSpacing)}, 0, 0);
    position: fixed;
    top: 0;
    transition: transform 300ms ease-in;
    width: ${navHeight};
    z-index: 10;
  `};
`;
const HeaderTitle = styled.h3`
  display: inline-block;
`;
const SidebarTitle = HeaderTitle.extend`
  display: none;
  ${media.tablet`display: block;`};
  height: ${navHeight};
  line-height: ${navHeight};
  text-align: center;
  width: 100%;
`;
const SidebarTitleLink = styled(Link)`
  color: ${foreground};
  text-decoration: none;
`;
const HeaderLinkBar = styled.nav`
  float: right;
`;
const NavLink = styled(Link)`
  display: inline-block;
  color: ${foreground};
  background-color: ${primary};
  height: ${navHeight};
  line-height: ${navHeight};
  padding: 0 ${rhythm(1)};
  text-decoration: none;
  transition: border 100ms ease-in;
  ${props =>
    props.to !== "/" &&
    typeof location !== "undefined" &&
    new RegExp(props.to).test(location.pathname)
      ? `border-bottom: ${rhythm(1 / 3)} solid ${accentDark};`
      : ""};
  &:hover {
    background-color: ${accentDark};
  }
  &:active {
    background-color: ${accentDarker};
  }

  ${media.tablet`
    border: none;
    width: 100%;
    ${props =>
      props.title
        ? ""
        : `
      background-color: ${background};
      &:hover {
        background-color: ${accentLighter};
      }
    `}
  `};
`;
const NavSearch = styled.form`
  background-size: ${rhythm(1)};
  display: inline-block;
  margin: 0 0 0 ${rhythm(1 / 2)};
  ${media.tablet`
    margin-top: ${rhythm(1 / 2)};
  `};
`;
const NavInput = styled.input.attrs({ "aria-label": "Search Recipes" })`
  background-color: ${accentLight};
  background-image: url(${searchIcon});
  background-size: ${rhythm(2 / 3)};
  background-repeat: no-repeat;
  background-position: ${rhythm(1 / 2)} center;
  border: none;
  border-radius: ${rhythm(1 / 4)};
  color: ${foreground};
  height: ${rhythm(3 / 2)};
  padding: 0 ${rhythm(1 / 2)} 0 ${rhythm(3 / 2)};
  width: ${rhythm(7)};
  transition: background-color 100ms, width 100ms;

  &::placeholder {
    color: ${foreground};
  }

  &:hover {
    background-color: ${accentLighter};
  }

  &:focus {
    background-color: ${accentLighter};
    width: ${rhythm(9)};
  }

  ${media.tablet`
    background-color: ${background};
    border: 1px solid ${primary};
    margin: 0 ${rhythm(1)};
    width: ${rhythm(8)};
    &:hover, &:focus {
      background-color: ${background};
      width: ${rhythm(8)};
    }
  `};
`;

type State = { open: boolean, search: string };
export default class extends PureComponent<{}, State> {
  state = { search: "", open: false };

  onSearch = (event: SyntheticEvent<HTMLFormElement>) => {
    event.preventDefault();
    push(`/recipes/?search=${this.state.search}`);
  };

  onType = (event: SyntheticInputEvent<HTMLInputElement>) => {
    this.setState({ search: event.currentTarget.value });
  };

  onToggleSidebar = (event: SyntheticEvent<HTMLElement>) => {
    event.preventDefault();
    event.stopPropagation();
    this.setState(prevState => ({ open: !prevState.open }));
  };

  render() {
    return (
      <NavContainer>
        <SidebarToggle open={this.state.open} onClick={this.onToggleSidebar}>
          =
        </SidebarToggle>
        <SidebarTitle>
          <SidebarTitleLink to="/">Muzen</SidebarTitleLink>
        </SidebarTitle>
        {this.state.open && <Backdrop toggle={this.onToggleSidebar} />}
        <Header open={this.state.open}>
          <NavLink to="/">
            <HeaderTitle>Muzen</HeaderTitle>
          </NavLink>
          <HeaderLinkBar>
            <span onClick={this.onToggleSidebar}>
              <NavLink to="/recipes/">Recipes</NavLink>
              <NavLink to="/articles/benefits/">Benefits</NavLink>
              <NavLink to="/about/">About us</NavLink>
            </span>
            <NavSearch onSubmit={this.onSearch}>
              <NavInput
                id="search"
                placeholder="search recipes"
                onChange={this.onType}
              />
            </NavSearch>
          </HeaderLinkBar>
        </Header>
      </NavContainer>
    );
  }
}
