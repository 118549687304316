// @flow

import React from "react";
import type { Node } from "react";
import styled from "styled-components";
import { Link } from "gatsby";
import { accent, accentDark } from "../utils/colors";

export const linkStyles = `
  color: ${accent};
  text-decoration: none;
  transition: color 100ms;
  &:hover {
    color: ${accentDark};
    text-decoration: underline;
  }
`;

const StyledLink = styled.a`
  ${linkStyles};
`;
export const ExternalLink = (props: { href: string, children: Node }) => (
  <StyledLink {...props} target="_blank" rel="noopener noreferrer" />
);

export default StyledLink.withComponent(Link);
