// @flow

import React, { PureComponent } from "react";
import { StaticQuery, graphql, Link } from "gatsby";
import type { GatsbyImageSizes } from "gatsby-image";
import Carousel from "nuka-carousel";
import HeroImage from "./HeroImage";

type SlideProps = { sizes: GatsbyImageSizes, title: string, slug: string };
const Slide = ({ sizes, title, slug }: SlideProps) => (
  <Link to={slug}>
    <HeroImage title={title} sizes={sizes} />
  </Link>
);

type Node = {
  node: {
    id: string,
    frontmatter: {
      title: string,
      imageSrc: { childImageSharp: { sizes: GatsbyImageSizes } },
    },
    fields: { slug: string },
  },
};
type RecipesCarouselProps = {
  data: {
    file: { childImageSharp: { sizes: GatsbyImageSizes } },
    allMarkdownRemark: {
      edges: Array<Node>,
    },
  },
};
class RecipesCarousel extends PureComponent<RecipesCarouselProps> {
  componentDidMount() {
    // *sigh* https://github.com/FormidableLabs/nuka-carousel/issues/265
    setTimeout(() => {
      if (typeof window !== "undefined") {
        window.dispatchEvent(new Event("resize")); // eslint-disable-line
      }
    }, 0);
  }

  render() {
    const {
      data: {
        file: {
          childImageSharp: { sizes: recipesSizes },
        },
        allMarkdownRemark: { edges },
      },
    } = this.props;
    return (
      <Carousel heightMode="current" initialSlideHeight={300}>
        <Slide
          sizes={recipesSizes}
          title="See all our recipes!"
          slug="/recipes/"
        />
        {edges.map(({ node: { id, frontmatter, fields } }) => (
          <Slide
            key={id}
            sizes={frontmatter.imageSrc.childImageSharp.sizes}
            title={frontmatter.title}
            slug={fields.slug}
          />
        ))}
      </Carousel>
    );
  }
}

export default () => (
  <StaticQuery
    query={graphql`
      query RecipesCarouselQuery {
        file(relativePath: { eq: "img/cooking-class.jpg" }) {
          childImageSharp {
            sizes(maxWidth: 768) {
              ...GatsbyImageSharpSizes
            }
          }
        }
        allMarkdownRemark(
          filter: { fields: { isRecipe: { eq: true } } }
          sort: { fields: [frontmatter___date], order: DESC }
          limit: 5
        ) {
          edges {
            node {
              id
              frontmatter {
                title
                description
                imageSrc {
                  childImageSharp {
                    sizes(maxWidth: 768) {
                      ...GatsbyImageSharpSizes
                    }
                  }
                }
              }
              fields {
                slug
              }
            }
          }
        }
      }
    `}
    render={data => <RecipesCarousel data={data} />}
  />
);
