// @flow

import Typography from "typography";

const typography = new Typography({
  baseFontSize: "16px",
  baseLineHeight: "26px",
  headerFontFamily: ["Helvetica Neue", "Helvetica", "Arial", "sans-serif"],
  bodyFontFamily: ["Helvetica Neue", "Helvetica", "Arial", "sans-serif"],
  modularScales: [
    {
      scale: 2.4375,
    },
    {
      scale: "octave",
      maxWidth: "768px",
    },
  ],
  headerGray: 13,
  bodyGray: 13,
  headerWeight: "normal",
  bodyWeight: "normal",
  boldWeight: "bold",
});

const { rhythm, scale } = typography;
export { rhythm, scale, typography as default };
