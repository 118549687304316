// @flow

import { css } from "styled-components";
import { rhythm } from "./typography";

export const maxWidth = "768px";
export const sideSpacing = rhythm(1);

export const breakpoints = {
  desktop: 992,
  tablet: 768,
  phone: 480,
};
// Iterate through the sizes and create a media template
export const media = Object.keys(breakpoints).reduce((acc, label) => {
  // $FlowFixMe
  acc[label] = (...args: any) => css`
    @media (max-width: ${breakpoints[label]}px) {
      ${css(...args)};
    }
  `;
  return acc;
}, {});
