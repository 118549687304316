// @flow

import { keyframes } from "styled-components";

// eslint-disable-next-line
export const fadeIn = keyframes`
  from {
    opacity: 0;
  }
  to {
    opacity: 1;
  }
`;
