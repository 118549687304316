// @flow

import React from "react";
import type { Node } from "react";
import styled, { injectGlobal } from "styled-components";
import { Helmet } from "react-helmet";
import { rhythm } from "../utils/typography";
import { maxWidth, sideSpacing } from "../utils/sizes";
import NavigationBar from "./NavigationBar";
import Footer from "./Footer";

// eslint-disable-next-line
injectGlobal`
  html {
    overflow: auto;
  }
`;

type Props = { children: Node, uncontained?: Node };

const Container = styled.main`
  margin: 0 auto;
  max-width: ${maxWidth};
  padding: ${sideSpacing};
  padding-top: ${rhythm(1.5)};
`;

const Layout = ({ children, uncontained }: Props) => (
  <main>
    <Helmet titleTemplate="%s | Muzen Honey" defaultTitle="Muzen Honey">
      <html lang="en" />
      <meta
        name="description"
        content="Discover our best honey recipes: desserts, dishes, cocktails, starters and breads! "
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/favicon-96x96.png"
      />
      <link
        rel="icon"
        type="image/png"
        sizes="96x96"
        href="/ms-icon-310x310.png"
      />
    </Helmet>
    <NavigationBar />
    {uncontained}
    <Container>{children}</Container>
    <Footer />
  </main>
);
Layout.defaultProps = { uncontained: null };
export default Layout;
