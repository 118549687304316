// @flow

import React from "react";
import styled from "styled-components";
import { graphql } from "gatsby";
import type { GatsbyImageSizes } from "gatsby-image";
import Layout from "../components/Layout";
import RecipesCarousel from "../components/RecipesCarousel";
import HeroImage from "../components/HeroImage";
import Link from "../components/Link";
import Title from "../components/Title";
import { accent } from "../utils/colors";
import { media } from "../utils/sizes";
import { rhythm } from "../utils/typography";
import recipe from "../img/recipe.svg";
import health from "../img/health.svg";
import honey from "../img/honey.svg";

const InfoSection = styled.section`
  margin: ${rhythm(1)} 0;
  &:after {
    content: "";
    display: block;
    clear: both;
  }
`;
const InfoBlock = styled.div`
  float: left;
  text-align: center;
  width: 33.333%;
  &:not(:last-of-type) {
    padding-right: ${rhythm(1)};
  }
  ${media.phone`
    float: none;
    padding-rigth: 0;
    width: 100%;
  `};
`;
const InfoTitle = Title.withComponent("h3").extend`
  color: ${accent};
`;

type Props = {
  data: { file: { childImageSharp: { sizes: GatsbyImageSizes } } },
};
export default ({
  data: {
    file: {
      childImageSharp: { sizes },
    },
  },
}: Props) => (
  <Layout
    uncontained={
      <HeroImage sizes={sizes} title="Pure Natural Honey" maxHeight="50vh" />
    }
  >
    <InfoSection>
      <InfoBlock>
        <img src={recipe} height={80} alt="recipe" />
        <InfoTitle>Recipes</InfoTitle>
        <p>
          Check our <Link to="/recipes/">recipes</Link> to cook the best
          desserts and dishes using honey, and impress your family and friends!
        </p>
      </InfoBlock>
      <InfoBlock>
        <img src={health} height={80} alt="health" />
        <InfoTitle>Health Tips</InfoTitle>
        <p>
          Learn about all the{" "}
          <Link to="/articles/benefits/">natural benefits of honey</Link>, and
          prepare your own tonic and{" "}
          <Link to="/articles/cleansing-mask/">cleansing masks</Link> using our
          honey recipes!
        </p>
      </InfoBlock>
      <InfoBlock>
        <img src={honey} height={80} alt="honey" />
        <InfoTitle>Everything about honey</InfoTitle>
        <p>
          Learn everything about honey. How is it made, why is it great in any
          dish, and why is it so good for the health!
        </p>
      </InfoBlock>
    </InfoSection>

    <RecipesCarousel />
  </Layout>
);

export const query = graphql`
  query IndexQuery {
    file(relativePath: { eq: "img/honeycomb.jpg" }) {
      childImageSharp {
        sizes(maxWidth: 768) {
          ...GatsbyImageSharpSizes
        }
      }
    }
  }
`;
