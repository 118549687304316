// @flow

export const primary = "#ffbb00";
export const accentLighter = "#ffd663";
export const accentLight = "#ffca39";
export const accent = "#e76b00";
export const accentDark = "#c59000";
export const accentDarker = "#9b7200";
export const foreground = "rgba(0, 0, 0, 0.8)";
export const background = "white";
export const shadow = "rgba(0, 0, 0, 0.2)";
