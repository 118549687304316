// @flow

import React from "react";
import { Link } from "gatsby";
import styled from "styled-components";
import { rhythm } from "../utils/typography";
import { primary, accentDark } from "../utils/colors";
import { maxWidth, media, sideSpacing } from "../utils/sizes";

const FooterContainer = styled.div`
  background-color: ${primary};
  padding: ${rhythm(1)} ${sideSpacing};
  width: 100%;
`;
const FooterAltContainer = FooterContainer.extend`
  background-color: ${accentDark};
  padding: ${rhythm(0.5)} ${sideSpacing};
`;
const Footer = styled.div`
  display: flex;
  ${media.tablet`
    display: block;
  `};
  margin: 0 auto;
  max-width: ${maxWidth};
`;
const FooterSection = styled.section`
  margin-right: ${rhythm(2)};
  width: 33.33%;
  ${media.tablet`
    width: 100%;
  `};
`;
const FooterLink = styled(Link)`
  color: inherit;
  display: block;
  text-decoration: none;
`;

export default () => (
  <footer>
    <FooterContainer>
      <Footer>
        <FooterSection>
          <h3>Muzen Honey</h3>
          <p>
            The best honey recipes, health and beauty tips and learning hub!
          </p>
        </FooterSection>

        <FooterSection>
          <h3>Recipes</h3>
          <p>
            <FooterLink to="/recipes/">All Recipes</FooterLink>
            <FooterLink to="/recipes/?category=main+dish">Mains</FooterLink>
            <FooterLink to="/recipes/?category=dessert">Desserts</FooterLink>
          </p>
        </FooterSection>

        <FooterSection>
          <h3>Links</h3>
          <p>
            <FooterLink to="/articles/cleansing-mask/">
              Cleansing Mask
            </FooterLink>
            <FooterLink to="/contact/">Contact</FooterLink>
            <FooterLink to="/about/">About Us</FooterLink>
          </p>
        </FooterSection>
      </Footer>
    </FooterContainer>
    <FooterAltContainer>
      <Footer>
        <span role="img" aria-label="copyright">
          ©
        </span>{" "}
        2015-
        {new Date().getFullYear()} Copyright Muzen
      </Footer>
    </FooterAltContainer>
  </footer>
);
